import { addDays, addMonths, format, parseISO } from 'date-fns';
import useSWR, { mutate } from 'swr';
import { BBList, bbNamesNew, getTreatment, T_CustomerPrefs } from '..';
import {
  categoryIds,
  priceHoldCampaignCodes,
  productSkuIds,
  SPLITIO_KEY,
  STAFF_ACCOUNT_TYPE,
  VTVMigrationTypeCode,
} from '../config';
import { getAuthedPerson, getPhone, getServiceAddress, getTuiAddressCode } from '../helpers/customer';
import { formatMoney } from '../helpers/format';
import { KonakartService } from '../services/konakart';
import { MyAccountService } from '../services/myaccount';
import {
  ServiceStatus,
  T_Billing,
  T_Customer,
  T_Device,
  T_LinkAccount,
  T_MOP_Payload,
  T_Occurrence,
  T_PPVPayload,
  T_PPV_Mobile,
  T_Profile,
  T_ValidateAccount,
  UserGroupType,
} from '../types';
import {
  getActiveCampaigns,
  getDiscrepancies,
  isUserType,
  resolveBroadbandDataPackages,
  resolveBroadbandDevicePackages,
  resolveBroadbandServicePackages,
  resolveCablePackages,
  resolveVodafoneIglooPackages,
  resolveVoicePackages,
  resolveVoucherEligibility,
  updateProductsToMatchBillingServiceDetailsData,
  updateProductsToMatchBillingServiceDetailsVoice,
  updateStaffAccountQuantity,
} from './helper';

export class MyAccountSWR {
  private myAccountService: MyAccountService;
  private kkService: KonakartService;

  // Note: Can't use auto assign of class properties feature of typescript constructors
  // because babel does not support it currently.
  // The change is to allow WEB-50 (debugging of shared files) to work
  constructor(myAccountService: MyAccountService, kkService: KonakartService) {
    this.myAccountService = myAccountService;
    this.kkService = kkService;
  }

  updatePassword = (payload: { oldPassword: string; newPassword: string }) => {
    return this.myAccountService.updatePassword(payload);
  };

  updatePin = (payload: { newPin: string; oldPin?: string; reset: boolean }) => {
    return this.myAccountService.updatePin(payload);
  };

  verifyAccount = (payload: T_ValidateAccount) => {
    return this.myAccountService.verifyAccount(payload);
  };

  linkAccount = (payload: T_LinkAccount) => {
    return this.myAccountService.linkAccount(payload);
  };

  useProfile = () => {
    return useSWR('/profile', this.myAccountService.getProfile);
  };

  updateProfile = async (payload: Partial<T_Profile>) => {
    // optimistic update
    mutate('/profile', (current: T_Profile) => ({ ...current, ...payload }), false);
    return this.myAccountService.updateProfile(payload);
  };

  useCustomer = () => {
    return useSWR('/customer', this.myAccountService.getCustomer);
  };

  useCustomerOffers = () => {
    return useSWR('/offers', this.myAccountService.getOffers);
  };

  usePublicCustomerOffers = (subscriberKey: string) => {
    return useSWR(`/public/offers?subscriberKey=${subscriberKey}`, () =>
      this.myAccountService.getPublicOffers(subscriberKey)
    );
  };

  useCustomerInfo = () => {
    let { data: customer } = this.useCustomer();

    if (!customer) return {};

    let authedPerson = getAuthedPerson(customer);
    let primaryContactNumber = getPhone(customer);
    let installationAddress = getServiceAddress(customer);
    let tui = getTuiAddressCode(customer);

    return { primaryContactNumber, installationAddress, authedPerson, tui };
  };

  updateCustomer = (payload: Partial<T_Customer>) => {
    mutate('/customer', (current: T_Customer) => ({ ...current, ...payload }), false);
    return this.myAccountService.updateCustomer(payload);
  };

  usePPV = (type = 'MOVIE') => {
    return useSWR('/ppv' + type, () => this.myAccountService.getPPV(type));
  };

  bookPPV = (payload: T_PPVPayload) => {
    return this.myAccountService.bookPPV(payload);
  };

  updatePPVHistory = () => {
    mutate('/ppv/history');
  };

  usePPVHistory = () => {
    return useSWR('/ppv/history', () => this.myAccountService.getPPVHistory());
  };

  usePPVMobile = () => {
    return useSWR('/ppv/moible', () =>
      this.myAccountService.getPPVMobile().catch(() => {
        return { ppvSmsNumber: '' } as T_PPV_Mobile;
      })
    );
  };
  updatePPVMobile = (mobile: string) => {
    let p = this.myAccountService.updatePPVMobile(mobile);
    p.then(() => {
      mutate('/ppv/moible', (old: T_PPV_Mobile) => ({ ...old, ppvSmsNumber: mobile }), false);
    });
    return p;
  };
  deletePPVMobile = () => {
    let p = this.myAccountService.deletePPVMobile();
    p.then(() => {
      mutate('/ppv/moible', (old: T_PPV_Mobile) => ({ ...old, ppvSmsNumber: null }), false);
    });
    return p;
  };
  registerPPVMobile = (mobile: string) => {
    let p = this.myAccountService.registerPPVMobile(mobile);
    p.then(() => {
      mutate('/ppv/moible', (old: T_PPV_Mobile) => ({ ...old, ppvSmsNumber: mobile }), false);
    });
    return p;
  };

  useBilling = () => {
    let { data: customer } = this.useCustomer();

    let { data: profile } = this.useProfile();

    let result = useSWR('/billing', this.myAccountService.getBilling, { dedupingInterval: 10 * 60 * 1000 });
    let { data, ...rest } = result;

    let isVTVCustomer = isUserType(profile?.groups ?? [], UserGroupType.SKY_VTV_CUSTOMER);
    let staffAccount = customer?.type === STAFF_ACCOUNT_TYPE ? true : false;

    if (data?.services?.CableServiceOccurrence) {
      data.services.CableServiceOccurrence = updateStaffAccountQuantity(
        staffAccount,
        data.services.CableServiceOccurrence
      );
    }

    if (data?.campaigns && isVTVCustomer) {
      data.campaigns = data.campaigns.map(c => {
        if (c.active) {
          c.activeVTV = true;
          c.active = false;
        }
        return c;
      });
    }

    // HACK (Cannot do properly as no service codes -ETA March 2021) need to do paperless billing math calc to the monthly billing here
    let monthlyCharge = this.getMonthlyChargeIncPaperBilling(data);
    return { ...rest, data: data ? { ...data, monthlyCharge } : data } as typeof result;
  };

  // useBillingDetails = () => {
  //   let result = useSWR('/customerBillingDetails', this.myAccountService.getBillingDetails, {
  //     dedupingInterval: 10 * 60 * 1000,
  //   });
  //   return result.data;
  // };

  useIsCustomerInArrears = () => {
    let { data } = this.useBilling();

    return this.IsCustomerInArrears(data);
  };

  useIsCustomerInArrearsThirtyDays = () => {
    let { data } = this.useBilling();
    return this.IsCustomerInArrearsThirtyDays(data);
  };

  IsCustomerInArrears = (data: T_Billing | undefined) => {
    return (
      parseFloat(data?.ledger?.delinquentAmount61To90 ?? '0') > 0 ||
      parseFloat(data?.ledger?.delinquentAmount91To120 ?? '0') > 0 ||
      parseFloat(data?.ledger?.delinquentAmount121To150 ?? '0') > 0
    );
  };

  IsCustomerInArrearsThirtyDays = (data: T_Billing | undefined) => {
    return (
      parseFloat(data?.ledger?.delinquentAmount31To60 ?? '0') > 0 ||
      parseFloat(data?.ledger?.delinquentAmount61To90 ?? '0') > 0 ||
      parseFloat(data?.ledger?.delinquentAmount91To120 ?? '0') > 0 ||
      parseFloat(data?.ledger?.delinquentAmount121To150 ?? '0') > 0
    );
  };

  reloadBilling = () => {
    mutate('/billing');
  };
  reloadMop = () => {
    mutate('/mop');
  };
  useBillingInfo = () => {
    let { data: billing, isValidating: billingLoading } = this.useBilling();
    let { data: packages, isValidating: packageLoading } = this.usePackages();
    let bought = packages?.filter(p => p.quantityBought > 0) ?? [];
    let totalPrices = bought.reduce((prev, cur) => {
      return prev + (cur.priceBilled ?? 0) * cur.quantityBought;
    }, 0);

    let amountDue = parseFloat(billing?.currentTotalAmountDueCents ?? '0') / 100;
    let amountDueStr = formatMoney(amountDue);
    let monthlyCharge = parseFloat(billing?.monthlyCharge ?? '0');
    let monthlyChargeStr = formatMoney(monthlyCharge);
    let paymentDueDateStr = billing?.statementDueDate ? format(parseISO(billing?.statementDueDate), 'd/M/Y') : '';
    let discrepancies = getDiscrepancies(monthlyCharge, totalPrices, 0);
    let discrepanciesStr = formatMoney(discrepancies);
    let chargePeriod = billing?.statementDueDate
      ? `${format(addDays(parseISO(billing?.statementDueDate), 1), 'd.M')}  - ${format(
          addMonths(parseISO(billing?.statementDueDate), 1),
          'd.M'
        )}`
      : '';

    let loading = billingLoading || packageLoading;

    return {
      amountDue,
      amountDueStr,
      monthlyChargeStr,
      bought,
      discrepancies,
      discrepanciesStr,
      paymentDueDateStr,
      chargePeriod,
      loading,
    };
  };

  /**
   * This is a hack - we need to do to calculate the monthly charge of paperBilling if the billing.paperLessBilling is false
   * If users have paper billing = then need to update the monthly charge by the Konakart paper billing product price inc tax
   * REASONING:
   * ICOMS is unable to create us a category and billing doesn't have service codes for the paperLessBilling, so we need to calculate on web, instead of backend
   * @param billing
   */
  private getMonthlyChargeIncPaperBilling = (billing?: T_Billing): string => {
    let { data: products } = this.useProducts();
    // HACK (Cannot do properly as no service codes -ETA March 2021) need to do paperless billing math calc to the monthly billing here
    if (billing && products) {
      if (billing.paperLessBilling) {
        // set as the monthly charge amount
        return `${parseFloat(billing?.monthlyCharge ?? '0') / 100}`;
      } else {
        let paperBillingProduct = products.find((p: { sku: string }) => p.sku === productSkuIds.paperBilling.primary);
        if (paperBillingProduct) {
          return `${parseFloat(billing?.monthlyCharge ?? '0') / 100 + paperBillingProduct.priceIncTax}`;
        }
        // weird condition if the paperBilling product doesn't exist in konakart
        return `${parseFloat(billing?.monthlyCharge ?? '0') / 100}`;
      }
    }
    return '';
  };

  usePaperBillingProduct = (billing?: T_Billing) => {
    let { data: products } = this.useProducts();
    let isPaperlessBilling = billing?.paperLessBilling;
    let paperBillingProduct = products?.find((p: { sku: string }) => p.sku === productSkuIds.paperBilling.primary);
    return { isPaperlessBilling, paperBillingProduct };
  };

  useProducts = () => {
    const getAllProducts = async () => {
      let { enabled: overrideProductInfoFromKK } = await getTreatment(
        SPLITIO_KEY.SKYWEB_OVERRIDE_PRODUCT_INFO_FROM_KONAKART
      );
      return await this.kkService.getAllProduct(overrideProductInfoFromKK);
    };

    return useSWR('/products', getAllProducts);
  };

  useCoupon = (code: string) => {
    return useSWR(`/coupon/${code}`, () => this.kkService.getCoupon(code));
  };

  useBoxes = () => {
    return useSWR('/boxes', this.myAccountService.getBoxes);
  };

  useBundles = () => {
    return useSWR('/bundles', this.kkService.getAllBundles);
  };

  useOffers = () => {
    return useSWR('/offers', this.myAccountService.getOffers);
  };

  usePackages = () => {
    const { data: boxes, isValidating: boxesLoading, error: boxesError } = this.useBoxes();
    const { data: products, isValidating: productsLoading, error: productsError } = this.useProducts();
    const { data: billing, isValidating: billingLoading, error: billingError } = this.useBilling();
    const { tui } = this.useCustomerInfo();

    const {
      data,
      occurrenceNotFound,
      isBroadcastTier,
      isStarterActive,
      isAccessActive,
      hasPendingBox,
    } = resolveCablePackages(products, boxes, billing);

    // Get all the active service details across all cable service occurrences
    const activeCableServiceDetails = billing?.services.CableServiceOccurrence?.filter(
      s => s.status === ServiceStatus.Active
    ).flatMap(x => x.serviceDetails);

    // Get all the active service details across all data service occurrences
    const activeDataServiceDetails = billing?.services.DataServiceOccurrence?.flatMap(x => x.serviceDetails).filter(
      s => s.serviceStatus === ServiceStatus.Active || s.serviceStatus === ServiceStatus.DataPendingOrder
    );

    // Get all the active voucher service details across all ott service occurrences
    const activeOttServiceDetails = billing?.services.OTTServiceOccurrence?.filter(
      s => s.status === ServiceStatus.Active
    ).flatMap(x => x.serviceDetails);

    // Get all the pending service details across all data service occurrences
    const pendingDataServiceDetails = billing?.services.DataServiceOccurrence?.flatMap(x => x.serviceDetails).filter(
      s => s.serviceStatus === ServiceStatus.PendingInstall
    );

    // Get all service details accross all telephone service occurrences
    const voiceActiveServiceDetails = billing?.services.TelephoneServiceOccurrence?.filter(
      s => s.status === ServiceStatus.Active
    ).flatMap(x => x.serviceDetails);

    // Get all service details accross all telephone service occurrences
    const voicePendingServiceDetails = billing?.services.TelephoneServiceOccurrence?.filter(
      s => s.status === ServiceStatus.PendingInstall
    ).flatMap(x => x.serviceDetails);

    const dataCampaign = getActiveCampaigns(
      billing?.campaigns ?? [],
      c => c.containedServices.DataServiceOccurrence !== null
    );
    const voiceCampaign = getActiveCampaigns(
      billing?.campaigns ?? [],
      c => c.containedServices.TelephoneServiceOccurrence !== null
    );

    // Get active campaigns (only filter by active flag)
    const activeCampaigns = billing?.campaigns?.filter(x => x.active);

    // Category Ids of products that may need to be updated with the values given in the billing service details
    const categoryIdsForUpdatingProducts = [categoryIds.broadband, categoryIds.broadbandDevices];

    updateProductsToMatchBillingServiceDetailsData(
      data,
      activeDataServiceDetails,
      categoryIdsForUpdatingProducts,
      dataCampaign
    );

    // Update method specific to voice
    updateProductsToMatchBillingServiceDetailsVoice(data, voiceActiveServiceDetails, voiceCampaign);

    const {
      hasActiveMeshBroadbandPackage,
      hasPendingMeshBroadbandPackage,
      hasActiveOrPendingMeshBroadbandPackage,
      hasActiveBroadbandPackage,
      hasPendingBroadbandPackage,
      hasActiveOrPendingBroadbandPackage,
      hasSummerSet,
    } = resolveBroadbandDataPackages(activeDataServiceDetails, pendingDataServiceDetails, billing?.campaigns);

    const {
      isActiveIglooCustomers,
      isActiveVodafoneCustomers,
      isActiveDigiRentalCustomer,
    } = resolveVodafoneIglooPackages(activeCableServiceDetails, activeCampaigns);

    const isInArrears = this.IsCustomerInArrears(billing);
    const isTuiZero = tui === '0';

    const isCustomerNeedsAccountUpdate =
      isActiveIglooCustomers || isActiveVodafoneCustomers || isActiveDigiRentalCustomer || isInArrears || isTuiZero;

    const isEligibleForVoucher = resolveVoucherEligibility(activeOttServiceDetails, [
      productSkuIds.voucherEligible.primary,
    ]);

    const isVoucherActive = resolveVoucherEligibility(activeOttServiceDetails, [productSkuIds.voucherActive.primary]);

    const { hasActiveRouterDevice, hasActiveMeshDevice, hasPendingMeshDevice } = resolveBroadbandDevicePackages(
      activeDataServiceDetails,
      pendingDataServiceDetails
    );
    const { hasActiveStaticIp, hasPendingStaticIp } = resolveBroadbandServicePackages(
      activeDataServiceDetails,
      pendingDataServiceDetails
    );

    const { hasActiveVoicePackage, hasPendingVoicePackage } = resolveVoicePackages(
      voiceActiveServiceDetails,
      voicePendingServiceDetails
    );

    // first data occurrence is the broadband occurrence
    // if status is -1 means there's a pending order from vocus
    const hasPendingBroadbandOrder =
      billing?.services.DataServiceOccurrence?.[0]?.status === ServiceStatus.DataPendingOrder;

    let summerSetAccount;

    if (hasSummerSet) {
      summerSetAccount = {
        isBBAndVoice: hasActiveBroadbandPackage && hasActiveVoicePackage,
        isBBOnly: hasActiveBroadbandPackage && !hasActiveVoicePackage,
        isVoiceOnly: !hasActiveBroadbandPackage && hasActiveVoicePackage,
        isDTH: Boolean(activeCableServiceDetails?.length),
      };
    }

    const cableActiveCampaigns = getActiveCampaigns(
      billing?.campaigns ?? [],
      c => c.containedServices.DataServiceOccurrence !== null || c.containedServices.TelephoneServiceOccurrence !== null
    );

    const hasPriceHoldCampaign = cableActiveCampaigns.some(item => priceHoldCampaignCodes.includes(item.promotionCode));

    // override price for price hold customer
    if (hasPriceHoldCampaign) {
      const oldBroadbandPlans = Object.values(bbNamesNew);
      data?.forEach(product => {
        if (BBList.includes(product.sku)) {
          product.price0 = oldBroadbandPlans.find(item => product.sku === item.primary)?.price0;
          product.price1 = oldBroadbandPlans.find(item => product.sku === item.primary)?.price1;
          product.priceIncTax =
            product.priceBilled ?? oldBroadbandPlans.find(item => product.sku === item.primary)?.price0;
        }
      });
    }

    const broadbandOnlyAccount = hasActiveOrPendingBroadbandPackage && !isStarterActive && !isAccessActive;
    // used by mobile app
    const wasExVTVCustomer = activeCableServiceDetails?.some(
      sd => sd.serviceCode === VTVMigrationTypeCode.TRANSFER || sd.serviceCode === VTVMigrationTypeCode.STANDALONE
    );

    return {
      data,
      isStarterActive,
      isAccessActive,
      isValidating: boxesLoading || productsLoading || billingLoading,
      billing,
      error: billingError || boxesError || productsError,
      dataLoaded: boxes && billing && products,
      accountIssue: occurrenceNotFound,
      hasActiveMeshBroadbandPackage,
      hasPendingMeshBroadbandPackage,
      hasActiveOrPendingMeshBroadbandPackage,
      hasActiveBroadbandPackage,
      isEligibleForVoucher,
      isVoucherActive,
      hasPendingBroadbandPackage,
      hasActiveOrPendingBroadbandPackage,
      hasActiveRouterDevice,
      hasActiveMeshDevice,
      hasPendingMeshDevice,
      hasActiveStaticIp,
      hasPendingStaticIp,
      isBroadcastTier,
      hasActiveVoicePackage,
      hasPendingVoicePackage,
      hasPendingBroadbandOrder,
      broadbandOnlyAccount,
      isActiveIglooCustomers,
      isActiveVodafoneCustomers,
      isActiveDigiRentalCustomer,
      isCustomerNeedsAccountUpdate,
      isInArrears,
      hasPendingBox,
      activeCableServiceDetails,
      summerSetAccount,
      wasExVTVCustomer,
      hasPriceHoldCampaign,
    };
  };

  useBillingDocs = () => {
    return useSWR('/billing/history', this.myAccountService.getBillingDocs);
  };
  useBillingOtherDocs = () => {
    return useSWR('/billing/other', this.myAccountService.getBillingOtherDocs);
  };

  useMop = () => {
    return useSWR('/mop', this.myAccountService.getMop);
  };
  useMopInfo = () => {
    const { data: mops } = this.useMop();
    let activeMop = mops?.find(m => m.mopasgnd === 'Y');

    let isDebit = activeMop && activeMop?.mthdofpaycode === '3';
    let isCredit = activeMop && activeMop?.mthdofpaycode !== '3';

    return {
      isDebit,
      isCredit,
    };
  };

  updateMop = (payload: Partial<T_MOP_Payload>) => {
    return this.myAccountService.updateMop(payload).then(() => {
      this.myAccountService.getMop().then(r => mutate('/mop', r, false));
    });
  };

  useCustomerId = () => {
    return useSWR(`/customerId`, async () => (await this.kkService.getTempCustomerId()).r, {
      dedupingInterval: 1000 * 60 * 60,
    });
  };

  useDevices = () => {
    return useSWR('/devices', this.myAccountService.getDevices);
  };

  updateDevices = async (payload: Partial<T_Device>) => {
    return this.myAccountService.updateDevices(payload).then(() => {
      mutate(
        '/devices',
        (current: T_Device[]) => {
          let index = current.findIndex(d => d.deviceId === payload.deviceId);
          // a popular pattern for mutating array[index] from redux world...
          return [...current.slice(0, index), { ...current[index], ...payload }, ...current.slice(index + 1)];
        },
        false
      );
    });
  };

  deleteDevices = async (deviceId: T_Device['deviceId']) => {
    mutate(
      '/devices',
      (current: T_Device[]) => {
        let index = current.findIndex(d => d.deviceId === deviceId);
        return [...current.slice(0, index), ...current.slice(index + 1)];
      },
      false
    );
    return this.myAccountService.deleteDevices(deviceId);
  };

  updateBox = async (payload: { serialNumber: string; accountNumber: string; nickName: string }) => {
    const response = await this.myAccountService.updateBox(payload);

    await mutate(
      '/boxes',
      (current: T_Occurrence[]) => {
        let index = current.findIndex(d => d.serialNumber === payload.serialNumber);

        // a popular pattern for mutating array[index] from redux world...
        return [...current.slice(0, index), { ...current[index], ...payload }, ...current.slice(index + 1)];
      },
      false
    );

    return response;
  };

  useEntitlements = () => {
    return useSWR('/entitlements', this.myAccountService.getEntitlements);
  };

  useTracking = () => {
    return useSWR('/tracking', this.myAccountService.getTracking);
  };
  // customer preferences starts
  updateCustomerPref = (payload: T_CustomerPrefs, update = false) => {
    mutate('/customer-preferences', (current: T_CustomerPrefs) => payload, false);
    return update ? this.myAccountService.updateCustomerPref(payload) : Promise.resolve(payload);
  };

  useCustomerPref = () => {
    return useSWR('/customer-preferences', this.myAccountService.getCustomerPref);
  };
  // customer preferences ends
}
